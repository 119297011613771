import React from "react";
import "./Hero.css";
import Disclaimer from "../utils/Disclaimer";

function Hero() {
  const main = process.env.REACT_APP_MAIN;

  return (
    <div className="hero-wrapper">
      <div className="hero-overlay"></div>
      <div className="hero-container">
        <div className="hero-content">
{/* 
          <h1>Advocacia Criminal e Investigação Defensiva</h1>
          <p>
            Primamos por técnica, profissionalismo, confiança, ética e sigilo nos serviços prestados
          </p> */}

          <div>
            <h1>Rebeca Freitas</h1>
            <h2>Estética, Emagrecimento e Neurociência Compotamental</h2>
          </div>
          <p>Oferecemos aos nossos clientes resultados excepcionais, com uma abordagem individualizada e humanizada. Utilizamos conhecimentos avançados de neurociência para proporcionar um atendimento completo e eficaz aos nossos pacientes. </p>

          <a
            href="https://wa.me/5584991425834"
            target="_blank"
            rel="noreferrer"
            className='main-btn'>Entre em contato</a>
        </div>
      </div>
    </div>
  );
}

export default Hero;
