import React from 'react';
import './CTA.css';

function CTA() {
  return (
    <div className='CTA-container'>
        <h2>Deseja mudar de vida com segurança e profissionalismo?</h2>
        <p>Clique no botão e entre em contato agora mesmo</p>
        
      <a
        href="https://wa.me/5584996034138"
        target="_blank"
        rel="noreferrer"
        className='main-btn'>(84) 99603-4138</a>
        
    </div>
  );
}

export default CTA;
