import "./Footer.css";
import React, { useMemo } from "react";
import Logo from '../../assets/imgs/logorebecca.png';
import menuItems from './Btns-Content';
import MenuBtn from './MenuBtn';


const Footer = ({ scrollToComponent }) => {

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (

      <MenuBtn className="footer-menu-btn" action={action} data={menuItem} scrollToComponent={scrollToComponent} />

    ));
  }, [menuItems]);

  return (
    <footer className="footer-background">
      <div className="footer-container header-body-footer-width">
        <div class="footer-container-division">

          <div className="footer-wrap">
            <div className="footer-logo">
              <img className="footer-img" src={Logo} alt="logo"></img>
            </div>
            <div></div>
          
          </div>
          <div className="footer-wrap footer-content">
            <div className="footer-endereco">
              <h5>ENCONTRE-NOS</h5>
              <p>
                <span>Endereço</span>
                Edifício Blue Tower, Sala 201,
               
                Avenida Lima e Silva, 1611
             
                Lagoa Nova, Natal, CEP: 59075-710
                
                <br></br>
                CNPJ n.º 33.136.164/0001-08.

              

              </p>
            </div>
         
           
          
          </div>
        </div>

        <div className="footer-wrap footer-bottom-container">
          <div className="footer-copy">
            <p>&copy; 2024 Rebeca Freitas.</p>
            <p className="luana-name">
            Desenvolvido por{" "}
            <a className="luana-link"
              href="https://wa.me/5584996060201"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Luana Salmito
            </a>
          </p>
          </div>
          <div className="footer-socials">
            
            <a
              href="https://www.instagram.com/rebecafreitas_o/"
              target="_blank"
              aria-label="instagram"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="https://wa.me/5584996034138"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-whatsapp"></i>
            </a>

            <a
              href="mailto:rebecafreitas.estetica@gmail.com"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i class="uil uil-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
