import React from "react";
import "./Especialidades.css";
import Logo from '../../assets/imgs/esteticaema.png';
import especialidadesData from './especialidades.json';

function Especialidades() {
  return (
    <div class="especialidades-wrapper">
      <div className="especialidades-container">
        <div className="header">
          <img src={Logo} alt="logo"></img>
          <h2>Conheça nossas chaves de transformação.
          </h2>
        </div>
        <div className="servicos-grid">
          {especialidadesData.map((item, index) => (
            <div key={index}>
              <div>
              <i className="uil uil-dumbbell"></i>
                <h3>{item.titulo}</h3>
              </div>
              <p>{item.texto}</p>
              <a
                href="https://wa.me/5584996034138"
                target="_blank"
                rel="noreferrer"
              >
                <i class="uil uil-angle-double-right"></i> Fale conosco
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Especialidades;
