const type = "Button";

const menuItems = [
    {
        label: "Início",
        type: type,
        to: "inicio",
    },
    {
        label: "Principais Serviços",
        type: type,
        to: "Principais Serviços",

    },
    {
        label: "Sobre",
        type: type,
        to: "sobre",
    },
    {
        label: "Contato",
        type: type,
        to: "contato",
    }
];

export default menuItems;
