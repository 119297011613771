import React from 'react';
import './Depoimentos.css';

function Depoimentos() {
  return (
    <div>
      <div className='teste'>
        <h2>Avaliações dos nossos clientes</h2>
      </div>
      <div className=''>
      <div class="elfsight-app-e500b637-4350-4c1d-99b3-95374e1856cf" data-elfsight-app-lazy></div>
      </div>
      <div className='teste2'>

      </div>
    </div>
  );
}

export default Depoimentos;
