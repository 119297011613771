import React from "react";
import "./SobreGabriel.css";
import Rebeca from "../../assets/imgs/rebeca.jpg";
function SobreGabriel() {
  return (
    <div class="sobre-gabriel-wrapper">
      <div className="sobre-gabriel-container">
        <div>
          <img className="img-rebeca" src={Rebeca} alt="Gabriel Bulhões"></img>
        </div>
        <div class="sobre-gabriel-content">
          <div>
            <h1>Rebeca Freitas</h1>
            <h2>Especialista em emagrecimento e mudanças de hábito.</h2>
          </div>
          <p>- Apaixonada pela transformação na vida das pessoas;</p>
          <p>- CEO da Rebeca Freitas Estética e Emagrecimento;</p>
          <p>- Esteticista e Cosmetóloga;</p>
          <p>- Especialista em psicologia positiva;</p>
          <p>- Neuriocientista comportamental;</p>
          <p>- Transformações sustentáveis;</p>
          <p>- Uma pessoa de verdade;</p>


          <a class="main-btn" href="https://wa.me/5584996034138" aria-label="Whatsapp Icon" target="_blank" rel="noreferrer">Fale Conosco!</a>
        </div>
      </div>
    </div>
  );
}

export default SobreGabriel;
