import React from "react";
import "./PontosFortes.css";
import Neuro from "../../assets/imgs/3.svg";
import Estetica from "../../assets/imgs/etica.svg";

import Emagrecimento from "../../assets/imgs/1.svg";
function PontosFortes() {
  return (
    <div className="pontos-fortes-wrapper">
      <div className="pontos-fortes-container">
        <div>
          <img className="imgs-diferencias" src={Neuro} alt="" />
          <h4>Neurociência no Emagrecimento</h4>
          <p>
            O emagrecimento com base na neurociência comportamental foca em
            modificar hábitos e pensamentos para promover uma alimentação
            saudável e exercícios regulares. Essa abordagem também melhora a
            saúde mental e o bem-estar, contribuindo para a manutenção do peso
            saudável.
          </p>
        </div>
        <div>
          <img className="imgs-diferencias-2" src={Emagrecimento} alt="" />
          <h4>Equipe Multidisciplinar em seu processo de transformação</h4>
          <p>
          Oferecemos um processo de emagrecimento e saúde de forma sustentável com acompanhamento diário, remoto, metodologia prática para o desenvolvimento das inteligências:  Alimentar, Emocional, Bem estar físico e Autoconhecimento.
          </p>
          <br />
          <p>
          Tudo guiado por Estrategista de emagrecimento, nutricionista, psicóloga, educador físico e assistente virtual dando suporte em todo processo da sua mudança pra você nunca se sentir sozinho. 

          </p>
        </div>
        <div>
          <img className="imgs-diferencias" src={Estetica} alt="" />
          <h4>Sessões de NeuroEstetica   
          </h4>
          <p>
          Oferecemos tratamentos inovadores da estética, uma união da neurociências para os resultados irem além do visual e terem impacto na sua saúde física e cognitiva. 
Manter o final: utilizamos técnicas avançadas e personalizadas para realçar a sua beleza de forma eficaz e saudável. Uma verdadeira imersão de bem estar e estética.  
 
          </p>
        </div>
      </div>
    </div>
  );
}

export default PontosFortes;
