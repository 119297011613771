import React, { useState } from 'react';
import './FAQ.css';
import faqData from './faqData.json';
import logo from '../../assets/imgs/logo4.svg';

function FAQ() {
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const toggleItem = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((item) => item !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  return (
    <><div className='faq-wrapper'>
      
      <div className="titulos">
      <img className='logo4' src={logo} alt="" />
        <h2>Perguntas Frequentes</h2>

      </div>
        <div className='faq-container'>
          {faqData.map((item, index) => (
            <div key={index} className='faq-item'>
              <div className='faq-question'>
                <button onClick={() => toggleItem(index)}>
                  <h3>{item.pergunta}</h3>
                  <span>{expandedIndexes.includes(index) ? '-' : '+'}</span>
                </button>
              </div>
              {expandedIndexes.includes(index) && <p>{item.resposta}</p>}
            </div>
          ))}
        </div>
    </div>
    </>
  );
}

export default FAQ;
