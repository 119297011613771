import React from 'react';
import './CTAMaior.css';

function Depoimentos() {
  return (
    <div class="depoimentos-wrapper">
      <div className='depoimentos-container'>
          <div className="depoimentos-content">
           <h2>Está com dúvidas?</h2>
          <a class="main-btn" href="https://wa.me/5584996034138" aria-label="Whatsapp Icon" target="_blank" rel="noreferrer">Fale Conosco!</a>
          </div>
          <div className='overlay'></div>
      </div>
    </div>
  );
}

export default Depoimentos;
