import React from "react";
import "./Contato.css";

function Contato() {
  return (
    <div class="contato-wrapper">
      <div className="contato-container">
        <div className="map-div">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15876.940919045435!2d-35.2166071!3d-5.8224162!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b255405dd2ad0d%3A0x2149cc2dc522fa0f!2sRebeca%20Freitas%20-%20Est%C3%A9tica%20e%20Emagrecimento!5e0!3m2!1spt-BR!2sbr!4v1716055041088!5m2!1spt-BR!2sbr" 
            width="100%"
            height="100%"
            style={{ border: 0, padding: 0, margin: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="contato-content">
          <h2>CONTATO</h2>
          <h3>Fale conosco!</h3>
          <p>
            <i class="fa fa-whatsapp"></i>(84) 99603-4138
          </p>
         
          <p>
            <i class="uil uil-envelope"></i>rebecafreitas.estetica@gmail.com
          </p>
          <p>
            <i class="uil uil-location-point"></i>Edifício Blue Tower - Av. Lima e Silva, 1611 - Sala 201 - Lagoa Nova, Natal - RN, 59075-710
          </p>
          <div className="socials">
          
            <a
              href="https://www.instagram.com/rebecafreitas_o/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="https://wa.me/5584996034138"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-whatsapp"></i>
            </a>

            <a
              href="mailto:rebecafreitas.estetica@gmail.com"
              target="_blank"
              rel="noreferrer"
              aria-label="email principal"
            >
              <i class="uil uil-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contato;
